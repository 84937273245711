@import "../../styles/mixins";

.lf-nav-btn {
  width: 100%;
  color: $dark;
  height: 3rem;
  font-size: 1.1rem;
  text-align: start;
  padding: 0.6rem 1rem;
  font-family: $normalFont;
  background-color: $navBgColor;
  border-bottom: 1px solid white;
  &:hover:not(:disabled) {
    text-decoration: underline;
    filter: brightness(95%);
    cursor: pointer;
  }
}
