//
//  This file includes the available colors.
//  NB: Please use the non-suffixed colors when possible, e.g. $color-blue instead of $color-blue-1.
//  For more information check: https://confluence.seges.dk/display/CROP/Farver
//

// Primary Color
$color-blue-1: #076471;
$color-blue-2: #4e808d;
$color-blue-3: #7da3ad;
$color-blue-4: #b4cad2;
$color-blue-5: #cadee1;
$color-blue: $color-blue-1;

// Secondary Colors
$color-green-1: #09562c;
$color-green-2: #4f734a;
$color-green-3: #7c9877;
$color-green-4: #b4c5b0;
$color-green-5: #43b05c;
$color-green-6: #008f31;
$color-green: $color-green-2;

$color-brown-1: #4b3e31;
$color-brown-2: #766251;
$color-brown-3: #9c816b;
$color-brown-4: #bf9c81;
$color-brown: $color-brown-2;

$color-gray-1: #646464;
$color-gray-2: #969696;
$color-gray-3: #c8c8c8; // often used for borders
$color-gray-4: #ebebeb;
$color-gray-5: #fbfbf9;
$color-gray: $color-gray-2;
$color-disabled: $color-gray-3;

$color-sky-1: #9ddcf9;
$color-sky-2: #c3e4fa;
$color-sky-3: #d6ecfb;
$color-sky-4: #dcebf0;
$color-sky-5: #eaf5fd;
$color-sky: $color-sky-2;

$color-sand-1: #c8c7b2;
$color-sand-2: #d4d3c2;
$color-sand-3: #e0e0d4;
$color-sand-4: #f0f0e9;
$color-sand: $color-sand-4;

$color-yellow-1: #ffd800;
$color-yellow-2: #f9e067;
$color-yellow-3: #fbe999;
$color-yellow-4: #fdf4c9;
$color-yellow: $color-yellow-2;

$color-orange-1: #e95d0f;
$color-orange-2: #dd8854;
$color-orange-3: #e7ac82;
$color-orange-4: #f2d3b9;
$color-orange-5: #ee6c00;
$color-orange: $color-orange-1;

$color-red-1: #e10630;
$color-red-2: #f2464a;
$color-red-3: #f98f82;
$color-red-4: #fdcbbe;
$color-red: $color-red-2;

// Custom Colors
$color-white: #ffffff;
$color-black: #000000;
$color-none: transparent;

// Transparent Colors
// We use rgba for cross browser support
$color-white-overlay: rgba(255, 255, 255, 0.5);
$color-white-opaque-bg: rgba(255, 255, 255, 0.8);
$color-black-overlay: rgba(0, 0, 0, 0.5);
$color-transparent-border: unquote("#00000061");

// Font colors
// The icon-font uses normal colors for coloring
$seges-dark: #555555;
$font-color-1: #333333;
$font-color-2: #999999; // help texts, descriptions etc.
$font-color-3: #eeeeee;
$font-color-4: #777;
$font-color: $font-color-1;

// Box Shadows (based on material)
$box-shadow-1: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
$box-shadow-2: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$box-shadow-3: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
$box-shadow-4: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
$box-shadow-5: 0 3px 1px -2px rgba(0, 0, 0, 0.1);
$box-shadow: $box-shadow-2;
