$blue: #006471;
$dark: #555555;
$hoverColor: #4e808d;
$navBgColor: #e0e0d4;
$transparentHoverColor: rgba($blue, 0.08);

$btnPadding: 0.1rem 1.5rem 0 1.5rem;

:scope {
  --borderRadius: 0px;
}

$boldFont: LFPressSansBold;
$normalFont: LFPressSans;
