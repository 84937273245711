@import "./seges-colors";
@import "./variables";
@import "./mixins";

mat-icon {
  color: $seges-dark;
}

// Progressbar
.mat-progress-bar-fill::after {
  background-color: $color-blue;
}

.mat-progress-bar-buffer {
  background: #e4e8eb;
}

// Tabs
.mat-tab-label-active {
  font-weight: bold !important;
  color: black !important;
}
.mat-tab-link,
.mat-tab-label {
  opacity: 1 !important;
  font-size: 18px;
  font-family: $lf-font-PressSans-bold;
}

// Dialog
.cdk-global-scrollblock {
  position: static;
  // Removes the horrible window scrollbar that squeezes the site when a dialog is open
  overflow: hidden !important;
}

// Snack panels
.info-snackbar {
  .mat-simple-snackbar {
    .mat-simple-snackbar-action {
      color: $color-orange;
    }
  }
}

// Tooltips
.mat-tooltip {
  font-size: 13px;
  white-space: pre-line;
}

// Expansion panels
.mat-expansion-panel {
  padding: 0 !important;
  margin-top: -1px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-color: transparent !important;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}
.mat-expansion-panel-header {
  @include border();
  background-color: white;
  padding: 0 $mm !important;
  &:hover {
    background-color: rgba($color-sand-4, 0.2) !important;
    cursor: pointer;
  }
}
.mat-expansion-panel-body {
  @include border();
  background-color: transparent !important;
  padding: 0 !important;
  margin-left: $lm;
  margin-top: -1px;
}
.mat-expansion-panel-spacing {
  margin: $mm 0 !important;
}

// Slide toggles
.mat-slide-toggle-content {
  white-space: normal !important;
  line-height: 1rem !important;
}

.mat-slide-toggle.mat-checked:not(.mat-enabled) .mat-slide-toggle-bar {
  background-color: $color-blue-3;

  .mat-slide-toggle-thumb {
    background-color: $color-blue;
  }
}

// Form fields
.mat-form-field-suffix {
  align-self: flex-end;
  padding-bottom: 0.15rem;
}

// Hide step arrows on number input

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Side drawer
.mat-drawer-inner-container {
  overflow: overlay !important;
}

.mat-select-panel {
  max-width: inherit!important;
}