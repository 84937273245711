@import "./seges-colors.scss";
@import "./variables.scss";

html {
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  height: 100%;
  min-height: 400px;
  width: 100%;
  position: fixed; // Disable bounce on Safari
  // Prevent screen from flashing blue on tap android
  -webkit-tap-highlight-color: transparent;

  font-size: 15px;
  @media screen and (max-width: $mobile-width){
    font-size: 13px;
  }
}

a, p, pre, label, td, th {
  font-family: $lf-font;
  color: $seges-dark;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $lf-font-PressSans;
  font-weight: normal;
  color: $seges-dark;
}
