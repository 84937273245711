@import "./seges-colors.scss";
@import "src/styles/variables";

@mixin border() {
  border: 1px solid $color-gray-3;
}

@mixin hoverHighlight($cursor: pointer) {
  &:hover {
    filter: brightness(90%);
    cursor: $cursor;
  }
}

@mixin two-line-clamp() {
  white-space: normal;
  line-height: 1.1em;
  max-height: 2.2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin sticky($top: 0px) {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: $top;
}

@mixin page-root() {
  min-height: calc(100% - 80px);
  padding: 0 $sm $sm $sm;
 }
