@import "variables";

@mixin onHover($color: $hoverColor) {
  &:hover:not(:disabled) {
    background-color: $color;
    cursor: pointer;
  }
}

@mixin onDisabled($color: white, $bgColor: grey) {
  &:disabled {
    background-color: $bgColor;
    color: $color;
  }
}
