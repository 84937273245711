@import "./seges-colors.scss";

// Responsive breakpoints
$s-mobile-width: 360px;
$mobile-width: 420px;
$s-tablet-width: 768px;
$tablet-width: 990px;
$l-tablet-width: 1024px;
$s-desktop-width: 1280px;

// Margins
$tm: 4px; // Tiny
$sm: 8px; // Small
$mm: 16px; // Medium
$lm: 32px; // Large
$hm: 64px; // Huge

// Constants
$header-height: 60px;
$sub-header-height: 50px;
$footer-height: 96px;
$indent-size: 24px;

// Fonts
$lf-font: "Arial", " Verdana", " sans-serif";
$lf-font-PressSans: "LFPressSans", $lf-font;
$lf-font-PressSans-bold: "LFPressSansBold", $lf-font;
