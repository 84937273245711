:scope {
  --borderRadius: 0px;
}

:scope {
  --borderRadius: 0px;
}

.base-btn {
  transition: all 0.2s ease-out;
  border-radius: var(--borderRadius);
  background-color: #006471;
  font-family: LFPressSansBold;
  padding: 0.1rem 1.5rem 0 1.5rem;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  height: 2.3rem;
  outline: none;
  border: none;
  color: white;
}

:scope {
  --borderRadius: 0px;
}

.lf-btn:hover:not(:disabled) {
  background-color: #4e808d;
  cursor: pointer;
}
.lf-btn:disabled {
  background-color: grey;
  color: white;
}

:scope {
  --borderRadius: 0px;
}

.lf-nav-btn {
  width: 100%;
  color: #555555;
  height: 3rem;
  font-size: 1.1rem;
  text-align: start;
  padding: 0.6rem 1rem;
  font-family: LFPressSans;
  background-color: #e0e0d4;
  border-bottom: 1px solid white;
}
.lf-nav-btn:hover:not(:disabled) {
  text-decoration: underline;
  filter: brightness(95%);
  cursor: pointer;
}

:scope {
  --borderRadius: 0px;
}

.lf-icon-btn {
  background-color: transparent;
  justify-content: center;
  height: 2rem !important;
  width: 2rem !important;
  transform: scale(0.8);
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  color: #006471;
}
.lf-icon-btn:disabled {
  background-color: transparent;
  color: grey;
}
.lf-icon-btn:first-child {
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
.lf-icon-btn:hover:not(:disabled) {
  transform: scale(1.2);
  cursor: pointer;
}

:scope {
  --borderRadius: 0px;
}

.lf-trans-btn {
  color: #006471;
  background-color: transparent;
}
.lf-trans-btn:hover:not(:disabled) {
  background-color: rgba(0, 100, 113, 0.08);
  cursor: pointer;
}
.lf-trans-btn:disabled {
  background-color: transparent;
  color: grey;
}

:scope {
  --borderRadius: 0px;
}

.lf-outline-btn {
  color: #006471;
  border: solid 0.15rem #006471;
  background-color: transparent;
}
.lf-outline-btn:hover:not(:disabled) {
  background-color: rgba(0, 100, 113, 0.08);
  cursor: pointer;
}
.lf-outline-btn:disabled {
  background-color: transparent;
  color: grey;
  border: solid 0.15rem grey;
}

:scope {
  --borderRadius: 0px;
}

.lf-square-icon-btn {
  border-radius: var(--borderRadius);
  background-color: transparent;
  justify-content: center;
  padding: 0.3rem 0.5rem;
  display: inline-flex;
  align-items: center;
  color: #006471;
}
.lf-square-icon-btn:disabled {
  background-color: transparent;
  color: grey;
}
.lf-square-icon-btn.active, .lf-square-icon-btn:active {
  background: white;
}
.lf-square-icon-btn.active :first-child, .lf-square-icon-btn:active :first-child {
  color: #006471;
}
.lf-square-icon-btn:hover:not(:disabled) {
  cursor: pointer;
}