@font-face {
  font-family: "LFPressSans";
  src: url("../assets/fonts/LFPressSans.woff2") format("woff2"), url("../assets/fonts/LFPressSans.woff") format("woff"),
    url("../assets/fonts/LFPressSans.ttf") format("truetype"),
    url("../assets/fonts/LFPressSans.svg#LFPressSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LFPressSans";
  src: url("../assets/fonts/LFPressSans-Italic.woff2") format("woff2"),
    url("../assets/fonts/LFPressSans-Italic.woff") format("woff"),
    url("../assets/fonts/LFPressSans-Italic.ttf") format("truetype"),
    url("../assets/fonts/LFPressSans-Italic.svg#LFPressSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "LFPressSansBold";
  src: url("../assets/fonts/LFPressSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/LFPressSans-Bold.woff") format("woff"),
    url("../assets/fonts/LFPressSans-Bold.ttf") format("truetype"),
    url("../assets/fonts/LFPressSans-Bold.svg#LFPressSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
