@import "../../styles/mixins";

.lf-square-icon-btn {
  @include onDisabled(grey, transparent);
  border-radius: var(--borderRadius);
  background-color: transparent;
  justify-content: center;
  padding: 0.3rem 0.5rem;
  display: inline-flex;
  align-items: center;
  color: $blue;
  &.active,
  &:active {
    background: white;
    :first-child {
      color: $blue;
    }
  }
  &:hover:not(:disabled) {
    cursor: pointer;
  }
}
