@import "../../styles/variables";

.base-btn {
  transition: all 0.2s ease-out;
  border-radius: var(--borderRadius);
  background-color: $blue;
  font-family: $boldFont;
  padding: $btnPadding;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  height: 2.3rem;
  outline: none;
  border: none;
  color: white;
}
