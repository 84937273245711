@import "../../styles/mixins";

.lf-icon-btn {
  @include onDisabled(grey, transparent);
  background-color: transparent;
  justify-content: center;
  height: 2rem !important;
  width: 2rem !important;
  transform: scale(0.8);
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  color: $blue;
  &:first-child {
    font-size: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  &:hover:not(:disabled) {
    transform: scale(1.2);
    cursor: pointer;
  }
}
