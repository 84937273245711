@import "../../styles/mixins";

.lf-outline-btn {
  color: $blue;
  border: solid 0.15rem $blue;
  background-color: transparent;
  @include onHover($transparentHoverColor);
  &:disabled {
    background-color: transparent;
    color: grey;
    border: solid 0.15rem grey;
  }
}
